<template>
  <div></div>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'Logout',
  mounted () {
    this.$http.post('/api/auth/logout').then(() => {
      delete Vue.prototype.$http.defaults.headers['X-Csrf-Token'];
      this.$store.commit('setUserID', 0);
      this.$store.commit('setUserEmail', '');
      this.$store.commit('setUserFirstName', '');
      this.$store.commit('setUserType', '');
      this.$store.commit('setUserAvatar', null);
      this.$store.commit('setUserPermissions', null);
      this.$router.push({ name: 'Login' });
    }).catch((error) => {
      console.log(error);
      delete Vue.prototype.$http.defaults.headers['X-Csrf-Token'];
      this.$store.commit('setUserID', 0);
      this.$store.commit('setUserEmail', '');
      this.$store.commit('setUserFirstName', '');
      this.$store.commit('setUserType', '');
      this.$store.commit('setUserAvatar', null);
      this.$store.commit('setUserPermissions', null);
      this.$router.push({ name: 'Login' });
    });
  }
}
</script>
